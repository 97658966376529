body {
  margin: 0;
  padding: 0;
  font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system, system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, 
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /*color: '#404d5b !important';*/
  /*overflow-x: hidden; */
  /*color: #404d5b;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.vertical-timeline {
  width: 100% !important;
  margin: 2em 0em !important;
  color: #404d5b !important;
}

.vertical-timeline::before {
  background: #eee !important;
}

.vertical-timeline-element-content {
  box-shadow: none !important;
}

.vertical-timeline-element-icon {
  /*width: 60px !important;*/
  /*height: 60px !important;*/
}

.vertical-timeline-element-content {
  /*background: #eee !important;*/
  border: 1px solid #eee !important;
}

.vertical-timeline-element-content::before {
  border-right: 7px solid #eee !important;
}